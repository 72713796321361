.App {
  min-height: 100vh;
  text-align: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.App-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

.App-maps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.App-maps > * {
  margin: 1em;
}

.App-buttons {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.App-buttons > * {
  margin: 1em 0;
}

.App-notifications {
  width: 100%;
}

.App-notifications .toggles {
  margin: 1em auto;
  padding: 0 1em;
  max-width: 20em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-notifications .toggles > .toggle {
  margin: 1em 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-notifications .toggles label {
  margin: 0;
}