.Settings {
  width: 100%;
}

.Settings > .btn {
  width: 100%;
}

.Settings-open {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1em;
  display: flex;
  flex-direction: column;
  background-color: #282c34;
  margin: 0;
}

.Settings-open > aside {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.Settings-config {
  text-align: left;
  overflow: scroll;
  flex-grow: 1;
}

.Settings-config-error {
  color: red;
}

.Settings-config-edit {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
}